import { FLOWCODE_ROOT } from '@dtx-company/true-common/src/constants/root'
import { useRouter } from 'next/router'
import qs from 'query-string'

export const removeTrailingSlash = (url = ''): string => {
  return url.slice(-1) === '/' ? url.slice(0, -1) : url
}

const domain = FLOWCODE_ROOT

export const useCanonicalURL = (): string => {
  const router = useRouter()
  const path = removeTrailingSlash(router.asPath)
  const { url } = qs.parseUrl(`${domain}${path}`)
  return url
}
