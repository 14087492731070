import { RootState } from '@dtx-company/inter-app/src/redux/types'
import { getMobileV3NavTitle } from './utils'
import { theme } from '@dtx-company/design-system/src'
import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'

/* Lean hook for finding if the mobile top nav is visible
Takes into account screen size, feature flag, and route  */

export const useShouldShowV3MobileNavigation = (): boolean => {
  const { pathname } = useRouter()
  const { isMoreOptionsSheetOpen } = useSelector(
    (state: RootState) => state.appReducer.configReducer
  )
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation('nav')
  const navTitle = useMemo(() => {
    return getMobileV3NavTitle({ pathname, t })
  }, [pathname, t])

  //only show new nav on a page by page basis
  const shouldShowV3MobileTopNav = useMemo(() => {
    return isMobileOrTablet && Boolean(navTitle) && !isMoreOptionsSheetOpen
  }, [isMobileOrTablet, navTitle, isMoreOptionsSheetOpen])

  return shouldShowV3MobileTopNav
}
