const expKey = 'dismissedTrialIntro'

function getDismissedTrialIntro(): boolean {
  const storage = window.localStorage.getItem(expKey)
  return storage === 'true'
}

export function dismissTrialIntro(): void {
  if (typeof window === 'undefined') {
    return
  }
  window.localStorage.setItem(expKey, 'true')
}

export function hasDismissedTrialIntro(): boolean {
  if (typeof window === 'undefined') {
    return true
  }
  return getDismissedTrialIntro()
}
