import { Routes } from '@dtx-company/true-common/src/constants/routes'
import {
  TOP_NAV_BANNER_HEIGHT,
  TOP_NAV_BANNER_HEIGHT_MOBILE
} from '../TopNavBannerWrapper/TopNavBannerWrapper'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'
import { useTrialConfig } from '@app/common/src/hooks/useTrialConfig/useTrialConfig'

export const useTrialBannerState = (): { displayTrialBanner: boolean; bannerHeight: number } => {
  const router = useRouter()
  const trialConfig = useTrialConfig()
  const { isMobile } = useScreenSizeBreakpoints()
  const bannerHeight = isMobile ? TOP_NAV_BANNER_HEIGHT_MOBILE : TOP_NAV_BANNER_HEIGHT
  const displayTrialBanner = useMemo(() => {
    const routeBlockList = [
      Routes.SIGN_IN,
      Routes.SIGN_UP,
      Routes.FREE_QR_CODE_GENERATOR,
      Routes.BUY_PLAN_PAGE,
      Routes.ACCEPT_INVITE,
      Routes.ACCEPT_ORG_INVITE,
      Routes.VALIDATE_PRIVACY_REQUEST,
      Routes.NOT_FOUND
    ]
    const routeIsBlocked = routeBlockList.some(route => router.pathname === route)
    return !routeIsBlocked && Boolean(trialConfig?.inTrial)
  }, [trialConfig?.inTrial, router.pathname])

  return {
    displayTrialBanner,
    bannerHeight
  }
}
