import {
  DESKTOP_ADDITIONAL_LEFT_OFFSET_V3,
  DESKTOP_COLLAPSED_SIDE_DRAWER_EDGE_WIDTH_V3,
  DESKTOP_SIDE_DRAWER_WIDTH_V3,
  NAVIGATION_RAIL_WIDTH_V3
} from '@dtx-company/true-common/src/constants/layout'

export const getContentLayoutBoxLeftNavSpacing = (args: {
  shouldMountNavigationRail?: boolean
  shouldMountLeftSideDrawer?: boolean
  isV3DesktopSideDrawerOpen?: boolean
  isDesktop?: boolean
  isSideDrawerOpenViaHover?: boolean
  hideSiteNavsForEditor?: boolean
  fillWidth?: boolean
}): number => {
  const {
    shouldMountNavigationRail,
    shouldMountLeftSideDrawer,
    isV3DesktopSideDrawerOpen,
    isDesktop,
    isSideDrawerOpenViaHover,
    hideSiteNavsForEditor,
    fillWidth
  } = args
  const additionalLeftOffset = fillWidth ? 0 : DESKTOP_ADDITIONAL_LEFT_OFFSET_V3
  if (hideSiteNavsForEditor) return 0
  if (shouldMountNavigationRail) {
    if (shouldMountLeftSideDrawer) {
      if (isV3DesktopSideDrawerOpen && isDesktop && !isSideDrawerOpenViaHover) {
        return NAVIGATION_RAIL_WIDTH_V3 + DESKTOP_SIDE_DRAWER_WIDTH_V3 + additionalLeftOffset
      }
      return (
        NAVIGATION_RAIL_WIDTH_V3 +
        DESKTOP_COLLAPSED_SIDE_DRAWER_EDGE_WIDTH_V3 +
        additionalLeftOffset
      )
    }
    return NAVIGATION_RAIL_WIDTH_V3
  }
  return 0
}
