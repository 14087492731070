import { SVGProps, memo } from 'react'

function OndemandVideoSharpFilledSvg({
  color = 'currentColor',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M1 3V19H8V21H16V19H22.99L23 3H1ZM9 15V7L16 11L9 15Z" fill={color} />
    </svg>
  )
}

export default memo(OndemandVideoSharpFilledSvg)
