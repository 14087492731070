import {
  CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT,
  STG_CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT
} from '@dtx-company/true-common/src/constants/endpoints'
import { IS_DEVELOPMENT } from '@dtx-company/true-common/src/constants/env'
import { logger } from '@dtx-company/logger'

export async function clearImpersonationAccessTokenCookie(): Promise<void> {
  try {
    const res = await fetch(CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!res.ok) {
      await logger.logFetchError(res, { technicalArea: 'authentication' })
    }

    /*
      Locally, we communicate with gateway.stg.flowcode.com services which cannot be proxied since they are gRPC. 
      To auth with these services, we set and clear cookies for staging in local development. 
      Eventually, we can remove this workaround when we can proxy all stg service calls with a local gateway.
    */
    if (IS_DEVELOPMENT) {
      await fetch(STG_CLEAR_IMPERSONATION_ACCESS_TOKEN_COOKIE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  } catch (e) {
    logger.logError(e, { technicalArea: 'authentication' })
  }
}
