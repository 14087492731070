import { Box, theme } from '@dtx-company/design-system/src'
import { FC, ReactNode } from 'react'

export const TOP_NAV_BANNER_HEIGHT = 52
export const TOP_NAV_BANNER_HEIGHT_MOBILE = 45

export const TopNavBannerWrapper: FC<{
  children: ReactNode
  'data-testid': string
  positionRelative?: boolean
}> = ({ children, 'data-testid': dataTestId, positionRelative }) => {
  return (
    <Box
      data-testid={dataTestId}
      sx={{
        // allows mobile `MobileMoreOptionsSheet` to be above this component
        zIndex: theme.zIndex.speedDial - 1,
        display: 'flex',
        alignItems: 'center',
        height: [
          `${TOP_NAV_BANNER_HEIGHT_MOBILE}px`,
          `${TOP_NAV_BANNER_HEIGHT_MOBILE}px`,
          `${TOP_NAV_BANNER_HEIGHT}px`,
          `${TOP_NAV_BANNER_HEIGHT}px`
        ],
        backgroundColor: 'utility.upgrade',
        position: positionRelative ? 'relative' : 'fixed',
        top: 0,
        width: '100vw',
        p: [theme.spacing(0, 3), theme.spacing(2, 0)]
      }}
    >
      {children}
    </Box>
  )
}
