import {
  Dashboard,
  DashboardOutlined,
  FolderSharp,
  HomeRepairServiceOutlined,
  HomeRepairServiceSharp,
  IntegrationInstructionsOutlined,
  IntegrationInstructionsSharp,
  Language,
  LanguageOutlined,
  Leaderboard,
  LeaderboardOutlined,
  ManageAccounts,
  ManageAccountsOutlined,
  OndemandVideoSharp,
  PeopleAlt,
  PeopleAltOutlined
} from '@mui/icons-material'
import { MarketingPageRoutes, Routes } from '@dtx-company/true-common/src/constants/routes'
import { NavigationRailOptions, NavigtaionRailOptionType } from './types'
import FolderSharpOutline from '@dtx-company/shared-components/src/foundation/Icons/NavV3/FolderSharpOutline'
import Home from '@mui/icons-material/Home'
import HomeOutlined from '@mui/icons-material/HomeOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined'
import OndemandVideoSharpFilled from '@dtx-company/shared-components/src/foundation/Icons/NavV3/OndemandVideoSharpFilled'

export const ASSETS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.ASSETS,
  baseIcon: <FolderSharpOutline />,
  selectedIcon: <FolderSharp />,
  href: Routes.CODES
}

export const HOME_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.HOME,
  baseIcon: <HomeOutlined />,
  selectedIcon: <Home />,
  href: Routes.HOME
}

export const ANALYTICS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.ANALYTICS,
  baseIcon: <LeaderboardOutlined />,
  selectedIcon: <Leaderboard />,
  href: Routes.ANALYTICS
}

export const MANAGE_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.MANAGE,
  baseIcon: <ManageAccountsOutlined />,
  selectedIcon: <ManageAccounts />,
  href: Routes.MY_ORG_TEAMS
}

export const UNIFIED_TEMPLATES_DISCOVER_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.TEMPLATES,
  baseIcon: <DashboardOutlined />,
  selectedIcon: <Dashboard />,
  href: Routes.TEMPLATES_DISCOVER
}

export const UNIFIED_YOUR_TEMPLATES_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.TEMPLATES,
  baseIcon: <DashboardOutlined />,
  selectedIcon: <Dashboard />,
  href: Routes.TEMPLATES_YOUR_TEMPLATES
}

export const UNIFIED_YOUR_TEMPLATES_CREATE_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.TEMPLATES,
  baseIcon: <DashboardOutlined />,
  selectedIcon: <Dashboard />,
  href: Routes.TEMPLATES_YOUR_CREATE
}

export const VIDEO_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.VIDEO,
  baseIcon: <OndemandVideoSharp />,
  selectedIcon: <OndemandVideoSharpFilled />,
  href: Routes.VIDEO_TOOL,
  newTab: true
}

export const FORMS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.FORMS,
  baseIcon: <ListAltOutlinedIcon />,
  selectedIcon: <ListAltIcon />,
  href: Routes.CRM_FORMS
}

export const DOMAINS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.DOMAINS,
  baseIcon: <LanguageOutlined />,
  selectedIcon: <Language />,
  href: Routes.DOMAINS
}

export const TEAMS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.TEAMS,
  baseIcon: <PeopleAltOutlined />,
  selectedIcon: <PeopleAlt />,
  href: Routes.MY_ORG_MEMBERS
}

export const INTERNAL_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.INTERNAL,
  baseIcon: <HomeRepairServiceOutlined />,
  selectedIcon: <HomeRepairServiceSharp />,
  href: Routes.INTERNAL_TOOLS
}

export const DEVELOPERS_OPTION: NavigtaionRailOptionType = {
  label: NavigationRailOptions.DEVELOPERS,
  baseIcon: <IntegrationInstructionsOutlined />,
  selectedIcon: <IntegrationInstructionsSharp />,
  href: MarketingPageRoutes.DEVELOPER_PORTAL,
  newTab: true
}

export const NAVIGATION_RAIL_ZINDEX = 1110 //same as MUI appBar
export const ASSET_CREATION_DIALOG_ID = 'desktop_asset_creation_dialog_id'
