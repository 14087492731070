import {
  FLOWPAGE_PATHS,
  LEFT_NAV_PATHS,
  PLATFORM_NAV_PATHS
} from '@dtx-company/true-common/src/constants/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'

export function useNavigationRoutes(): {
  shouldShowPlatformTopNav: boolean
  shouldShowLeftNav: boolean
  isAuthRoute: boolean
  isFlowpageRoute: boolean
  isOnHomepage: boolean
  userIsLoggedInOnMobile: boolean
  currentPageIsPlatformRoute: boolean
  isAuthChecked: boolean
  isAuthenticated: boolean
} {
  const { pathname, query } = useRouter()
  const isAuthRoute = pathname === Routes.SIGN_IN || pathname === Routes.SIGN_UP

  const { isAuthenticated, isAuthChecked } = useAuthState()

  const { isMobile } = useScreenSizeBreakpoints()
  const currentPageIsPlatformRoute = useMemo(
    () => PLATFORM_NAV_PATHS.includes(pathname as Routes),
    [pathname]
  )
  const shouldShowLeftNav = useMemo(() => LEFT_NAV_PATHS.includes(pathname as Routes), [pathname])
  const userIsLoggedInOnMobile = Boolean(isAuthenticated) && isMobile
  const isCheckoutPage =
    pathname === useMemo(() => Routes.BUY_PLAN_PAGE || pathname === Routes.UPGRADE, [pathname]) // fix nav collapsing on these pages, see thread: https://flowcode.slack.com/archives/C01PMLNTK0F/p1691529689322289
  const shouldShowPlatformTopNav = !isCheckoutPage || isAuthenticated
  const isOnHomepage = pathname === Routes.INDEX

  return {
    shouldShowPlatformTopNav,
    shouldShowLeftNav,
    isAuthRoute,
    isFlowpageRoute: Boolean(
      FLOWPAGE_PATHS.includes(pathname as Routes) || (query.flowpage === 'true' && isAuthRoute)
    ),
    isOnHomepage,
    userIsLoggedInOnMobile,
    currentPageIsPlatformRoute,
    isAuthChecked,
    isAuthenticated
  }
}
