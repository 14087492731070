import { BoxProps } from '@dtx-company/shared-components/src'
import {
  DESKTOP_NAV_HEIGHT,
  DESKTOP_TOP_NAV_V3_HEIGHT,
  MOBILE_NAV_HEIGHT,
  MOBILE_NAV_V3_HEIGHT,
  MOBILE_NAV_V3_HEIGHT_WITH_CHIP,
  NAV_LOGGED_OUT_LINKS_BP,
  NAV_SHORT_LINKS_BP,
  NAV_V2_HEIGHT,
  PLATFORM_TOP_NAV_HEIGHT,
  WHITE_NAV_VARIANT_PATHS
} from '@dtx-company/true-common/src/constants/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { theme as dsTheme } from '@dtx-company/design-system/src'
import { theme } from '@dtx-company/shared-components/src/theme'
import { useIsMobileOrTablet } from '@dtx-company/app/shared/code/src/hooks/useIsMobileOrTablet'
import { useMediaQuery } from '@dtx-company/true-common/src/hooks/useMediaQuery'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import { useShouldShowV3MobileNavigation } from '@dtx-company/app/shared/common/src/hooks/navv3/useShouldShowV3MobileNavigation/useShouldShowV3MobileNavigation'
import { useTrialBannerState } from '@app/code/src/components/Homepage/TrialBanner/useTrialBannerState'

// elected to not use media query hooks for this conditional logic since that would result in a flicker - DF
const getNavBorderBottom = (path: Routes): BoxProps['borderBottom'] => {
  // TODO: Update this based on new nav ...? (EE)
  if (path === Routes.INDEX) {
    return ['none', 'none', 'none', `1px solid ${theme.colors.secondary.border}`]
  }

  return ''
}

const getNavBackgroundColor = (path: Routes): BoxProps['color'] => {
  if (path === Routes.INDEX) {
    return [
      theme.colors.primary.black,
      theme.colors.primary.black,
      theme.colors.primary.black,
      theme.colors.primary.white
    ]
  }

  return theme.colors.primary.black
}

const getNavLinkColor = (path: string): BoxProps['color'] => {
  if (path === Routes.INDEX) {
    return [
      theme.colors.primary.white,
      theme.colors.primary.white,
      theme.colors.primary.white,
      theme.colors.primary.black
    ]
  }

  return theme.colors.primary.white
}

const getNavProps = (
  path: Routes
): {
  backgroundColor: BoxProps['color']
  color: BoxProps['color']
  borderBottom: BoxProps['borderBottom']
} => {
  return {
    color: getNavLinkColor(path),
    backgroundColor: getNavBackgroundColor(path),
    borderBottom: getNavBorderBottom(path)
  }
}

export interface UseNavigationReturnType {
  showLinks: boolean
  shortLinks: boolean
  platformNavHeight: number
  navHeight: number
  disableNavLogo: boolean
  isSideNavBP: boolean
  navLinkColor: BoxProps['color']
  flowcodeLogo: string
  navBackgroundColor: BoxProps['backgroundColor']
  navBottomBorder: BoxProps['borderBottom']
  menuIconColor: string
  navLinkFontWeight: string
  displayBlackFlowcodeLogo: boolean
  flowpageLogoColor: 'black' | 'white'
}

export function useNavigation(isLoggedIn: boolean): UseNavigationReturnType {
  const isSideNavBP = useMediaQuery(`(max-width: 840px)`)
  const isMobile = useIsMobileOrTablet()
  const router = useRouter()
  const showLoggedOutLinks = useMediaQuery(`(min-width:${NAV_LOGGED_OUT_LINKS_BP}px)`)
  const shortLinks = useMediaQuery(`(max-width: ${NAV_SHORT_LINKS_BP}px)`)
  // nav transitions its height at this breakpoint
  const displayMobileSiteNav = useMediaQuery('(max-width: 831px)')
  const showLinks = isLoggedIn || showLoggedOutLinks
  const navHeight = displayMobileSiteNav ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT
  const platformNavHeight = isMobile ? MOBILE_NAV_HEIGHT : PLATFORM_TOP_NAV_HEIGHT
  const disableNavLogo = Boolean(isLoggedIn && !isSideNavBP)

  const path = router.pathname as Routes
  const {
    backgroundColor: navBackgroundColor,
    borderBottom: navBottomBorder,
    color: navLinkColor
  } = useMemo(() => getNavProps(path), [path])

  const displayBlackFlowcodeLogo = WHITE_NAV_VARIANT_PATHS.has(path) && !isMobile
  const flowcodeLogo = displayBlackFlowcodeLogo
    ? '/logos/flowcodeTM_horizontal_black.svg'
    : '/logos/flowcodeTM_horizontal_white.svg'
  // used for components that can't take styled system values
  const menuIconColor =
    WHITE_NAV_VARIANT_PATHS.has(path) && !isMobile
      ? theme.colors.primary.black
      : theme.colors.primary.white

  const navLinkFontWeight = WHITE_NAV_VARIANT_PATHS.has(path) && !isMobile ? 'normal' : 'bold'
  return {
    showLinks,
    disableNavLogo,
    navHeight,
    platformNavHeight,
    shortLinks,
    isSideNavBP,
    navLinkColor,
    navBackgroundColor,
    navBottomBorder,
    flowcodeLogo,
    menuIconColor,
    navLinkFontWeight,
    displayBlackFlowcodeLogo,
    flowpageLogoColor: 'white'
  }
}

export interface UseNavigationV2ReturnType {
  platformNavHeight: number
  navHeight: number
  isSideNavBP: boolean
}

export function useNavigationV2(): UseNavigationV2ReturnType {
  const isSideNavBP = useMediaQuery(`(min-width: 841px)`) // show side nav above 840px
  const router = useRouter()
  const showV3MobileNav = useShouldShowV3MobileNavigation()
  const isTabletOrLower = useMediaQuery(dsTheme.breakpoints.down('md'))
  const { displayTrialBanner, bannerHeight } = useTrialBannerState()

  const mobilePathHasChip = useMemo(
    () =>
      (router.pathname == Routes.CODES ||
        router.pathname == Routes.TRASH ||
        router.pathname == Routes.PAGES) &&
      !router.query.batchId,
    [router.pathname, router.query.batchId]
  )

  const mobileTopNavV3ComponentHeight = mobilePathHasChip
    ? MOBILE_NAV_V3_HEIGHT_WITH_CHIP
    : MOBILE_NAV_V3_HEIGHT
  const navHeight = displayTrialBanner ? NAV_V2_HEIGHT + bannerHeight : NAV_V2_HEIGHT
  const mobileTopNavV3Height = showV3MobileNav ? mobileTopNavV3ComponentHeight : 0
  const topNavV3Height = isTabletOrLower ? mobileTopNavV3Height : DESKTOP_TOP_NAV_V3_HEIGHT
  const platformNavHeight = topNavV3Height

  return {
    navHeight,
    platformNavHeight,
    isSideNavBP
  }
}
