import { Box } from '@dtx-company/design-system/src'
import {
  DESKTOP_SIDE_DRAWER_TOGGLE_TRANSITION_SECONDS,
  SIDE_NAV_V2_DESKTOP_WIDTH
} from '@dtx-company/true-common/src/constants/layout'
import { FC, ReactNode, useMemo } from 'react'
import { SxProps } from '@mui/system/styleFunctionSx'
import { getContentLayoutBoxLeftNavSpacing } from './utils'
import { useScreenSizeBreakpoints } from '@app/common/src/hooks/useScreenSizeBreakpoints'

export interface ContentLayoutBoxProps {
  displayV3: boolean
  showSideNav?: boolean
  children: ReactNode
  backgroundColor?: string | string[]
  opacity?: number
  shouldMountNavigationRail?: boolean
  shouldMountLeftSideDrawer?: boolean
  isV3DesktopSideDrawerOpen?: boolean
  isSideDrawerOpenViaHover?: boolean
  hideSiteNavsForEditor?: boolean
  fillHeight?: boolean
  fillWidth?: boolean
}

export const ContentLayoutBox: FC<ContentLayoutBoxProps> = ({
  children,
  opacity,
  shouldMountNavigationRail,
  shouldMountLeftSideDrawer,
  isV3DesktopSideDrawerOpen,
  backgroundColor,
  isSideDrawerOpenViaHover,
  hideSiteNavsForEditor,
  fillHeight,
  fillWidth,
  displayV3,
  showSideNav
}) => {
  const { isDesktop } = useScreenSizeBreakpoints()
  const leftNavSpacing = useMemo(() => {
    return getContentLayoutBoxLeftNavSpacing({
      shouldMountNavigationRail,
      shouldMountLeftSideDrawer,
      isV3DesktopSideDrawerOpen,
      isDesktop,
      isSideDrawerOpenViaHover,
      hideSiteNavsForEditor,
      fillWidth
    })
  }, [
    shouldMountNavigationRail,
    shouldMountLeftSideDrawer,
    isV3DesktopSideDrawerOpen,
    isDesktop,
    isSideDrawerOpenViaHover,
    hideSiteNavsForEditor,
    fillWidth
  ])

  const v3Styles: SxProps = {
    role: 'main',
    backgroundColor: backgroundColor,
    opacity: opacity,
    gridArea: 'content',
    display: 'block',
    maxWidth: '100vw',
    paddingLeft: `${leftNavSpacing}px`,
    transition: `padding-left, ${DESKTOP_SIDE_DRAWER_TOGGLE_TRANSITION_SECONDS}s`,
    ...(fillHeight ? { display: 'flex', flexFlow: 'column nowrap' } : undefined)
  }

  const v2Styles: SxProps = {
    gridArea: 'content',
    display: fillHeight ? 'flex' : 'block',
    flexFlow: 'column nowrap',
    maxWidth: '100vw',
    paddingLeft: {
      lg: showSideNav ? `${SIDE_NAV_V2_DESKTOP_WIDTH}px` : 0,
      xs: 0
    }
  }

  return (
    <Box
      data-testid={displayV3 ? 'content-layout-box-v3' : 'content-layout-box-v2'}
      sx={displayV3 ? v3Styles : v2Styles}
    >
      {children}
    </Box>
  )
}
