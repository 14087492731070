import { Button, Stack, Typography } from '@dtx-company/design-system/src'
import { clearImpersonatedUserData } from '@dtx-company/inter-app/src/redux/slices/user-slice'
import { clearImpersonationAccessTokenCookie } from '@dtx-company/ithaca-sdk/src'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useDispatch } from 'react-redux'
import { useIsImpersonating } from '@dtx-company/inter-app/src/redux/selectors/currentUserSelectors'
import { useRouter } from 'next/router'
import { useState } from 'react'

const IMPERSONATING_LABEL = 'Impersonating'
const STOP_BUTTON_LABEL = 'Stop Impersonating'
const IMPERSONATION_ENDED_LABEL = 'Impersonation ended. Data in this tab may not be valid.'

export const ImpersonationBanner = (): JSX.Element => {
  const isImpersonating = useIsImpersonating()
  const [loading, setLoading] = useState(false)
  const { jwt } = useAuthState()
  const dispatch = useDispatch()
  const { reload } = useRouter()

  const impersonatingLabel = `${IMPERSONATING_LABEL} ${jwt?.email ?? 'a user'}`

  const label = isImpersonating ? impersonatingLabel : IMPERSONATION_ENDED_LABEL

  const clearImpersonation = async (): Promise<void> => {
    setLoading(true)
    await clearImpersonationAccessTokenCookie()
    dispatch(clearImpersonatedUserData())
    reload()
  }

  return (
    <>
      {isImpersonating && (
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          sx={theme => ({
            backgroundColor: theme.palette.warning.light,
            pl: 3,
            fontWeight: theme.typography.fontWeightBold,
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 2000
          })}
        >
          <Typography variant="labelMedium">{label}</Typography>
          <Button onClick={clearImpersonation} loading={loading}>
            {STOP_BUTTON_LABEL}
          </Button>
        </Stack>
      )}
    </>
  )
}
