import {
  AodSharp,
  AutoFixHighSharp,
  BookSharp,
  DashboardCustomize,
  Explore,
  Extension,
  MotionPhotosAuto,
  Palette,
  QrCode,
  StyleOutlined
} from '@mui/icons-material'
import {
  DEVELOPERS_OPTION,
  DOMAINS_OPTION,
  TEAMS_OPTION,
  VIDEO_OPTION
} from '../NavigationRail/constants'
import {
  NavigationMenuListOption,
  NavigationMenuListSection
} from '../../../bottom/NavigationMenuV3/types'
import { Routes } from '@dtx-company/true-common/src/constants/routes'

export const DESKTOP_LEFT_SIDE_DRAWER_PATHS = [
  Routes.CODES,
  Routes.CODE,
  Routes.PAGES,
  Routes.TRASH,
  Routes.ASSETS,
  Routes.ARCHIVE_ASSETS,
  Routes.FOLDERS,
  Routes.CRM_FORMS,
  Routes.BUNDLES,
  Routes.TEMPLATES_DISCOVER,
  Routes.TEMPLATES_DISCOVER_CODES,
  Routes.TEMPLATES_DISCOVER_PAGES,
  Routes.TEMPLATES_YOUR_TEMPLATES,
  Routes.TEMPLATES_YOUR_CODES,
  Routes.TEMPLATES_YOUR_PAGES,
  Routes.TEMPLATES_YOUR_CREATE,
  Routes.SEARCH_RESULTS,

  // ANALYTICS ROUTES
  Routes.ANALYTICS,
  Routes.ANALYTICS_CODES,
  Routes.ANALYTICS_CONVERSIONS,
  Routes.ANALYTICS_PAGES,
  Routes.ANALYTICS_PAGE,
  Routes.PIXEL_MANAGEMENT,
  Routes.REPORTS,
  Routes.REPORT,
  Routes.ANALYTICS_EVENTS,
  Routes.ANALYTICS_INSIGHTS,

  //STUDIO ROUTES
  Routes.STUDIO,
  Routes.AUTO,
  Routes.CODEE,
  Routes.STUDIO_TEMPLATES,
  Routes.STUDIO_COMPONENTS,
  Routes.STUDIO_LIBRARY,
  Routes.EDIT_STUDIO,
  Routes.INTERNAL_TOOLS,
  Routes.BRAND_KIT
]

export const MANAGE_PATHS = [
  Routes.MY_ORG_TEAMS,
  Routes.MY_ORG_TEAM,
  Routes.MY_ORG_MEMBERS,
  Routes.MY_ORG_INVITATIONS,
  Routes.DOMAINS
]

export const STUDIO_MENU_SECTION: NavigationMenuListSection = {
  sectionTitle: 'Studio',
  options: [
    { label: 'Code Design', icon: <Palette />, href: Routes.STUDIO },
    { label: 'Auto Design', icon: <MotionPhotosAuto />, href: Routes.AUTO },
    { label: 'Upload Templates', icon: <DashboardCustomize />, href: Routes.STUDIO_TEMPLATES },
    { label: 'Components', icon: <Extension />, href: Routes.STUDIO_COMPONENTS },
    { label: 'Library', icon: <BookSharp />, href: Routes.STUDIO_LIBRARY }
  ]
}

export const AUTOBUILDER_CONFIG: NavigationMenuListSection = {
  sectionTitle: 'Tools',
  options: [
    {
      label: 'Autobuilder v1',
      icon: <AutoFixHighSharp />,
      href: Routes.CODEE,
      getIsSelected: (router, href) => router.asPath === href
    },
    {
      label: 'Autobuilder v2',
      icon: <AutoFixHighSharp />,
      href: `${Routes.CODEE}?version=2`,
      getIsSelected: (router, href) => router.asPath === href
    }
  ]
}

export const BRAND_KIT_OPTION: NavigationMenuListOption = {
  label: 'Brand Kit',
  icon: <StyleOutlined />,
  href: Routes.BRAND_KIT,
  getIsSelected: (router, href) => router.asPath === href
}

export const LEFT_SIDE_DRAWER_CREATE_TEMPLATE_BUTTON_ID = 'left-side-drawer-create-template-button'

export const UNIFIED_TEMPLATES_DISCOVER_MENU: NavigationMenuListSection[] = [
  {
    sectionTitle: 'Discover templates',
    options: [
      { label: 'All templates', icon: <Explore />, href: Routes.TEMPLATES_DISCOVER },
      { label: 'Flowcodes', icon: <QrCode />, href: Routes.TEMPLATES_DISCOVER_CODES },
      { label: 'Flowpages', icon: <AodSharp />, href: Routes.TEMPLATES_DISCOVER_PAGES }
    ]
  }
]

export const MANAGEMENT_MENU_OPTIONS: NavigationMenuListOption[] = [
  {
    label: TEAMS_OPTION.label,
    icon: TEAMS_OPTION.baseIcon,
    href: TEAMS_OPTION.href,
    getIsSelected: (router, href) =>
      [
        Routes.MY_ORG_TEAM,
        Routes.MY_ORG_TEAMS,
        Routes.MY_ORG_MEMBERS,
        Routes.MY_ORG_INVITATIONS
      ].includes(router.pathname as Routes)
  },
  {
    label: DOMAINS_OPTION.label,
    icon: DOMAINS_OPTION.baseIcon,
    href: DOMAINS_OPTION.href,
    getIsSelected: (router, href) => router.asPath === href
  },
  {
    label: DEVELOPERS_OPTION.label,
    icon: DEVELOPERS_OPTION.baseIcon,
    href: DEVELOPERS_OPTION.href,
    newTab: DEVELOPERS_OPTION.newTab
  }
]

export const VIDEO_MENU_OPTION: NavigationMenuListOption[] = [
  {
    label: VIDEO_OPTION.label,
    icon: VIDEO_OPTION.baseIcon,
    href: VIDEO_OPTION.href,
    newTab: VIDEO_OPTION.newTab
  }
]
