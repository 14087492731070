export interface NavigtaionRailOptionType {
  label: NavigationRailOptions
  baseIcon: JSX.Element
  selectedIcon: JSX.Element
  href: string
  newTab?: boolean
}

export enum NavigationRailOptions {
  HOME = 'Home',
  ASSETS = 'Assets',
  ANALYTICS = 'Analytics',
  TEMPLATES = 'Templates',
  STORE = 'Store',
  VIDEO = 'Video',
  FORMS = 'Forms',
  FLOWLEADS = 'Call',
  DOMAINS = 'Domains',
  TEAMS = 'Teams',
  INTERNAL = 'Internal',
  DEVELOPERS = 'Developers',
  MANAGE = 'Manage'
}
