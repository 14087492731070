import { EntityTypes, ModalTypes } from '../../redux/slices/modal'
import { ModalHookReturn, useModal } from '../../redux/slices/utils'

type UseProfilePictureModalState = ModalHookReturn & {
  props?: {
    entityType?: EntityTypes
    entityId?: string
    profilePicture?: string
    entityName?: string
  }
}

export function useProfilePictureModal(): UseProfilePictureModalState {
  return useModal(ModalTypes.PROFILE_PICTURE)
}
