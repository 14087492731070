import { RootState } from '@dtx-company/inter-app/src/redux/types'
import {
  setDesktopSideDrawer,
  setUserHasToggledSideDrawer
} from '@dtx-company/inter-app/src/redux/slices/config-slice'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/system'

interface UseDesktopV3SideDrawerStateType {
  isDesktopSideDrawerOpen: boolean
  toggleSideDrawerOpen: () => void
  setSideDrawerOpenViaHover: (open: boolean) => void
  sideDrawerOpenViaHover: boolean
}

export const useDesktopV3SideDrawerState = (): UseDesktopV3SideDrawerStateType => {
  const [sideDrawerOpenViaHover, setSideDrawerOpenViaHover] = useState(false)
  const isDesktopSideDrawerOpen = useSelector(
    (state: RootState) => state.appReducer.configReducer.isDesktopSideDrawerOpen
  )
  const userHasToggledSideDrawer = useSelector(
    (state: RootState) => state.appReducer.configReducer.userHasToggledSideDrawer
  )
  const dispatch = useDispatch()
  const toggleSideDrawerOpen = useCallback(() => {
    dispatch(setDesktopSideDrawer({ open: !isDesktopSideDrawerOpen }))
    if (!userHasToggledSideDrawer) dispatch(setUserHasToggledSideDrawer({ hasToggled: true }))
  }, [dispatch, isDesktopSideDrawerOpen, userHasToggledSideDrawer])
  const theme = useTheme()
  const shouldAutoOpenSideDrawer = useMediaQuery(theme.breakpoints.up('lg'))

  /* Opens the side drawer if the screen is large enough.
  Want to allow users to close, so we check if the user has interacted with the toggle already
  lest the drawer open anew after it's closed
  */
  useEffect(() => {
    if (shouldAutoOpenSideDrawer && !isDesktopSideDrawerOpen && !userHasToggledSideDrawer) {
      dispatch(setDesktopSideDrawer({ open: true }))
    }
  }, [shouldAutoOpenSideDrawer, userHasToggledSideDrawer, isDesktopSideDrawerOpen, dispatch])

  const shouldAutoCloseSideDrawer = useMediaQuery(theme.breakpoints.down('lg'))

  /* Closes the side drawer if the screen shrinks below the large breakpoint size */
  useEffect(() => {
    if (shouldAutoCloseSideDrawer) {
      dispatch(setDesktopSideDrawer({ open: false }))
    }
  }, [shouldAutoCloseSideDrawer, dispatch])

  return {
    isDesktopSideDrawerOpen: isDesktopSideDrawerOpen || sideDrawerOpenViaHover,
    toggleSideDrawerOpen,
    setSideDrawerOpenViaHover,
    sideDrawerOpenViaHover
  }
}
