import { DEFAULT_FLOWCODE_NICKNAME } from '@app/code/src/constants/flowcode'
import { Routes } from '@dtx-company/true-common/src/constants/routes'

/* Returns the page title of the mobile top nav
  Undefined if new top nav is not mounted */
export const getMobileV3NavTitle = (args: {
  pathname: string
  codeNickname?: string | undefined
  batchId?: string | string[] | undefined
  pageSlug?: string | undefined
  t: (_: string) => string
}): string | undefined => {
  const { pathname, codeNickname, batchId, pageSlug, t } = args
  switch (pathname) {
    case Routes.CODE:
      if (batchId) {
        return codeNickname?.length ? codeNickname : DEFAULT_FLOWCODE_NICKNAME
      }
      return t('Titles.Assets')
    case Routes.PAGES:
    case Routes.TRASH:
    case Routes.CODES:
      return t('Titles.Assets')
    case Routes.ASSETS:
      return t('Titles.Assets')
    case Routes.CREATE_FLOWCODE:
      return t('Titles.CreateFlowcode')
    case Routes.ACCOUNT:
      return t('Titles.Account')
    case Routes.EDIT_CODE:
      return t('Titles.EditCode')
    case Routes.FOLDERS:
      return t('Titles.Folders')
    case Routes.ARCHIVE_ASSETS:
      return t('Titles.Archive')
    case Routes.MY_ORG_TEAMS:
    case Routes.MY_ORG_MEMBERS:
    case Routes.MY_ORG_TEAM:
    case Routes.MY_ORG_INVITATIONS:
      return t('Titles.Teams')
    case Routes.DOMAINS:
      return t('Titles.Domains')
    case Routes.PAGE_EDITOR:
      return pageSlug?.length ? pageSlug : t('Titles.PageEditor')
    case Routes.ANALYTICS:
      return t('Titles.Dashboard')
    case Routes.ANALYTICS_CODES:
      return t('Titles.FlowcodeAnalytics')
    case Routes.ANALYTICS_PAGE:
    case Routes.ANALYTICS_PAGES:
      return t('Titles.FlowpageAnalytics')
    case Routes.ANALYTICS_CONVERSIONS:
      return t('Titles.ConversionAnalytics')
    case Routes.ANALYTICS_EVENTS:
      return t('Titles.FlowlyticsEvents')
    case Routes.ANALYTICS_INSIGHTS:
      return t('Titles.FlowlyticsInsights')
    case Routes.SEARCH_RESULTS:
      return t('Titles.SearchResults')
    case Routes.HOME:
      return t('Titles.Home')
    default:
      return undefined
  }
}
