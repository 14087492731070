import { SVGProps, memo } from 'react'

function FolderSharpOutlineSvg({
  color = 'currentColor',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.1 6H12.1L10.1 4H4.09998H2.09998V6V18V20H4.09998H20.1H22.1V18V8V6H20.1ZM20.1 18H4.09998V6H9.29998L11.3 8H20.1V18Z"
        fill={color}
      />
    </svg>
  )
}

export default memo(FolderSharpOutlineSvg)
