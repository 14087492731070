import {
  DESKTOP_LEFT_SIDE_DRAWER_PATHS,
  MANAGE_PATHS
} from '../../../../../../containers/nav/side/v3/DesktopV3SideDrawer/constants'
import { PLATFORM_NAV_PATHS } from '@dtx-company/true-common/src/constants/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'
import { useTheme } from '@mui/system'

export interface UseShouldShowV3DesktopNavigationType {
  shouldMountNavigationRail: boolean
  shouldMountLeftSideDrawer: boolean
  navigationRailMountShouldOverride: boolean
  shouldMountTopNav: boolean
  hideSiteNavsForEditor: boolean
}

export const useShouldShowV3DesktopNavigation = (): UseShouldShowV3DesktopNavigationType => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { pathname, query } = useRouter()
  const { isAuthenticated } = useAuthState()
  const onCodeDetailsPage = pathname === Routes.CODE && Boolean(query.batchId)

  const onLeftSideDrawerPath =
    DESKTOP_LEFT_SIDE_DRAWER_PATHS.concat(MANAGE_PATHS).includes(pathname as Routes) &&
    !onCodeDetailsPage
  const currentPageIsPlatformRoute = PLATFORM_NAV_PATHS.includes(pathname as Routes)
  const shouldMountNavigationRail = isDesktop && currentPageIsPlatformRoute && isAuthenticated
  const shouldMountLeftSideDrawer = isDesktop && onLeftSideDrawerPath && isAuthenticated
  const navigationRailMountShouldOverride = shouldMountNavigationRail && isAuthenticated

  const shouldMountTopNav = isDesktop && isAuthenticated
  const hideSiteNavsForEditor = false //leaving this for possible future experimentation - RB
  return {
    shouldMountNavigationRail,
    shouldMountLeftSideDrawer,
    navigationRailMountShouldOverride,
    shouldMountTopNav,
    hideSiteNavsForEditor
  }
}
